<template>
	<div>
		<main-nav :activeIndex="activeIndex"></main-nav>
		<div class="solution-header-container">
			<div class="solution-header-text">
				<h1 data-caption-delay="0" class="wow fadeInLeft">平台软件定制开发</h1>
				<p data-caption-delay="100" class="wow fadeInRight">提供稳定高效的测试管理、场景分析、二次开发、数据交换平台及其他各类定制化软件服务与解决方案</p>
				<div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)">
					<div>立即咨询</div>
				</div>
			</div>
		</div>
		<div class="sw-tab-title" ref="tab" :class="tabFixed ? getTabFixedClass() : ''">
			<div class="container">
				<el-tabs v-model="activeProduct" @tab-click="handleTabClick">
					<el-tab-pane label="管理平台" name="1">管理平台</el-tab-pane>
					<el-tab-pane label="数据平台" name="2">数据平台</el-tab-pane>
				</el-tabs>
			</div>
		</div>
		<div :class="tabFixed?'solution-tab-fixed':''">
			<div v-if="activeProduct === '1'">
				<section class="solution-intros platform-soft-solution-intro wow fadeInLeft">
					<div class="container">
						<div class="normal-title">解决方案介绍</div>
						<div class="all-intro">
							<el-row>
								<el-col :span="24">
									<p class="intro-detail">
										管理平台类解决方案旨在全面支撑架构设计、开发、仿真、测试V流程研发体系，并可对汽车生产、售后等其他阶段也提供相应支持，为提高工作效率、提升不同部门间的协同能力提供有力支撑。
									</p>
									<p class="intro-detail">
										平台采用SOA架构，通过数据资产复用、数据服务集成、工具链集成、结合需求部门的实际业务需求及业务流程，完成各类业务管理平台的搭建。通过各类管理平台的建设及运行，可逐步形成数据资产，为后续的数据上报、数据挖掘、数据分析、数据展现、数据运营等环节奠定基础。
									</p>

								</el-col>
							</el-row>
						</div>
					</div>
				</section>
				<section class="vehicle-solution-sections wow fadeInRight">
					<div class="container">
						<div class="normal-title">功能特点</div>
						<div class="all-function">
							<ul class="priority-list">
								<li><span>可集成性</span>可将各类外部系统、数据服务、工具设备、传感器等通过平台无缝集成，实现数据信息的互联互通，提升各类工具设备的使用效</li>
								<li><span>数据资产化</span>通过建立统一的数据标准以及数据的持续治理，逐步完成数据资产化，为数据分析挖掘，领导决策等活动提供科学依据和智能建议</li>
								<li><span>监控及预警</span>可对设计、开发、仿真、测试等过程中的各类数据、设备等信息通过预定义的指标进行监控及预警</li>
								<li><span>可定制性</span>可根据不同企业及部门的业务特点和实际需求进行个性化定制</li>
								<li><span>可扩展性</span>能够轻松适应企业及部门的业务规模扩展和业务调整等需求</li>
							</ul>
						</div>
					</div>
				</section>
				<section class="solution-intros platform-soft-solution-intro wow fadeInLeft">
					<div class="container">
						<div class="normal-title">核心组件</div>
						<div class="all-intro">
							<el-row>
								<el-col :span="24">
									<p class="intro-detail">
										企业服务总线、工具适配终端、数据采集终端、数据管理模块、数据质控模块等。
									</p>
								</el-col>
							</el-row>
						</div>
					</div>
				</section>
				<section class="solution-application wow fadeInRight">
					<div class="container">
						<div class="normal-title">应用领域</div>
						<div class="all-application">
							<div class="all-application">
								<div class="application-content">
									<div class="application-icon">
										<img src="../../../assets/images/application-picture.svg" alt="">
									</div>
									<div class="application-text">
										<p>车辆研发全周期适用</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section class="dyna-product-system-picture wow fadeInLeft">
					<div class="container">
						<div class="normal-title">系统图片</div>
						<el-row class="components-content" :gutter="40">
						<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" >
							<img src="../../../assets/images/solution-software-system-pic-1.png" alt=""  style="width: 95%;">
						</el-col>
						<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" style="text-align: right">
							<img src="../../../assets/images/solution-software-system-pic-2.png" alt="" style="width: 90%;">
						</el-col>
						<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
							<img src="../../../assets/images/solution-software-system-pic-3.png" alt="" style="width: 90%;">
						</el-col>
						<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12"  style="text-align: right">
							<img src="../../../assets/images/solution-software-system-pic-4.png" alt="" style="width: 90%;">
						</el-col>
						</el-row>
						
					</div>
				</section>
			</div>
			<div v-if="activeProduct === '2'" class="tab-container">
				<section class="solution-intros wow fadeInLeft">
					<div class="container">
						<div class="normal-title">解决方案介绍</div>
						<div class="all-intro">
							<el-row>
								<el-col :span="24">
									<p class="intro-detail">
										数据平台类解决方案旨在为主机厂构建标准化、流程化、自动化、一体化的数据管理体系。通过对主机厂各类业务数据的有效整合和管理，建立数据全生命周期的管理体系、标准体系、应用体系，提高数据使用效率，降低数据使用成本，提升数据利用价值。 
										数据平台基于SOA架构，涵盖数据采集、数据管理、数据交换、数据应用四大环节，融合数据采集、元数据管理、主数据管理、数据质控、数据交换、数据大屏等产品模块，打通数据治理、数据应用的各个环节，助力主机厂深化数字化转型。
									</p>
								</el-col>
							</el-row>
						</div>
					</div>
				</section>
				
				<section class="vehicle-solution-sections wow fadeInRight">
					<div class="container">
						<div class="normal-title">功能特点</div>
						<div class="all-function">
							<ul class="priority-list">
								<li><span>数据采集智能化</span>东信创智自研的数据采集组件采用B/S架构，将数据抽取、转换、上报等流程构建为统一平台，实现了ETL模型设计以及专业调度管理的统一建模运维系统</li>
								<li><span>数据质量控制</span>数据采集过程具备完善的数据质量评估体系，实现对数据质量控制指标的测量功能，能查看数据质量评估的结果和错误详情。平台数据质量支持同时在可视化大屏、监控系统、平板、手机等多个终端进行实时监控，随时掌握数据状态</li>
								<li><span>元数据驱动的全链路治理体系</span>以元数据为核心，驱动数据的标准化、规范化、可视化、融合化管理，打造端到端透明管控的一站式数据治理体系，实现数据标准的智能提炼和全域数据的融合治理</li>
								<li><span>数据标准化</span>梳理分析车企内分散管理的各业务单元数据模型，建立相关业务的主题域，建立企业内部统一的数据标准</li>
								<li><span>数据分析</span>提供数据的各类统计分析，并利用对数据的打标、数据建模等手段进行各类数据的统计、预测及分析</li>
								<li><span>数据同步</span>实时数据同步方案，集成不同平台和系统的数据，实现数据上报、数据下发、数据共享等功能</li>
								<li><span>可定制性</span>可基于客户需求对产品进行数据源适配、元数据管理、数据采集、数据统计分析、数据治理等模块的定制开发；提供数据采集、迁移、分析、质量控制等服务</li>
							</ul>
						</div>
					</div>
				</section>
				<section class="solution-intros platform-soft-solution-intro wow fadeInLeft">
					<div class="container">
						<div class="normal-title">核心组件</div>
						<div class="all-intro">
							<el-row>
								<el-col :span="24">
									<p class="intro-detail">
										数据采集组件、数据质控组件、元数据管理组件、主数据管理组件、数据交换组件、数据大屏。
									</p>
								</el-col>
							</el-row>
						</div>
					</div>
				</section>
				<section class="solution-application wow fadeInRight">
					<div class="container">
						<div class="normal-title">应用领域</div>
						<div class="all-application">
							<div class="all-application">
								<div class="application-content">
									<div class="application-icon">
										<img src="../../../assets/images/application-picture.svg" alt="">
									</div>
									<div class="application-text">
										<p>整车研发、售后、车联网、智驾等业务数据的治理、应用</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section class="dyna-product-system-picture wow fadeInLeft">
					<div class="container">
						<div class="normal-title">系统图片</div>
						<el-row class="components-content" :gutter="40">
						<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" >
							<img src="../../../assets/images/solution-software-system-pic-5.png" alt=""  style="width: 95%;">
						</el-col>
						<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" style="text-align: right">
							<img src="../../../assets/images/solution-software-system-pic-7.png" alt="" style="width: 100%;">
						</el-col>
						
						</el-row>
						<el-row class="components-content" :gutter="40">
						<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" >
							<img src="../../../assets/images/solution-software-system-pic-6.png" alt="" style="width: 95%;">
						</el-col>
						<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12"  style="text-align: right;">
							<img src="../../../assets/images/solution-software-system-pic-8.png" alt="" style="width: 100%;">
						</el-col>
						</el-row>
					</div>
				</section>
			</div>
			
		</div>


		<bzInfo style="margin-top: 0" />
		<main-footer></main-footer>
	</div>
</template>

<script>
	import mainNav from "@/components/MainNav.vue";
	import mainFooter from "@/components/MainFooter.vue";
	import bzInfo from "@/components/BzInfo.vue";

	export default {
		name: "platformSoftware",
		components: {
			mainFooter,
			mainNav,
			bzInfo
		},
		data() {
			return {
				tabFixed: false,
				activeIndex: '2-7',
				activeProduct: '1',
				chatUrl: "https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
				isMobile: false,
			}
		},
		mounted() {
			document.title = "平台软件定制开发 - 解决方案 - 东信创智"
			new this.$wow.WOW().init()
			window.addEventListener("scroll", this.handleScroll);
			this.isMobile = document.documentElement.clientWidth < 1200
			window.onresize = () => {
				return (() => {
					this.isMobile = document.documentElement.clientWidth < 1200;
				})();
			}
		},
		destroyed() {
			window.removeEventListener("scroll", this.handleScroll);
		},
		methods: {
			linkUrl(url) {
				window.open(url, '_blank') // 在新窗口打开外链接
				// window.location.href =this.indexro;  //在本页面打开外部链接
			},
			handleScroll() {
				if (document.documentElement.clientWidth > 1100) {
					let top = this.$refs.tab.offsetTop - document.documentElement.scrollTop - 70
					if (top < 0 && top > -630) {
						this.tabFixed = !this.tabFixed;
					} else if (document.documentElement.scrollTop > 630) {
						this.tabFixed = true;
					}
				} else {
					let top = this.$refs.tab.offsetTop - document.documentElement.scrollTop - 50
					if (top < 0 && top > -250) {
						this.tabFixed = !this.tabFixed;
					} else if (document.documentElement.scrollTop > 250) {
						this.tabFixed = true;
					}
				}
			},
			getTabFixedClass() {
				if (document.documentElement.clientWidth < 1100) {
					return 'sw-tab-title-mobile-fixed'
				} else {
					return 'sw-tab-title-fixed'
				}
			},
			handleTabClick() {
				if (document.documentElement.clientWidth > 1100 && document.documentElement.scrollTop > 630) {
					document.documentElement.scrollTop = 630
				} else if (document.documentElement.clientWidth < 1100 && document.documentElement.scrollTop > 250) {
					document.documentElement.scrollTop = 250
				}
			},
		}
	}
</script>